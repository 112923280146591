.rotated {
    transform-origin: center;
    transform: rotate(60deg);
    transform: scale(0.04);
  }

div.custom-tooltip {
  background: rgba(200, 200, 200, 0.2);
  border: 1px solid grey;
  min-width: 100px;
  padding: 5px;
  margin: 0px;
}
