
.jumbotron {
    color: text-primary;
}

.card-deck {
  justify-content: center;
}

.card-deck .card {
	max-width: 400px;
}